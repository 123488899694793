<template>
    <div>
      <b-container class="mt-5 mb-5">
        <h1 class="fs-4 font-weight-bold mb-4">Koppelingen</h1>

        <ValidationObserver v-slot="{ invalid, validate }">
          <b-form @submit.prevent="validate().then(saveForm)" autocomplete="off">
            <b-card class="mb-4">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="mb-3">
                  <label class="mb-0 required">Tickets versturen via WhatsApp</label>
                  <p class="text-muted mb-2">Geef de klanten de optie om tickets te versturen naar hun WhatsApp, de kosten hiervoor bedragen €0,25 en worden automatisch doorberekend aan de klant.</p>
                  <b-form-select v-model="form.tickets_via_whatsapp" :options="yesNoOptions"/>
                  <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
              <hr />
              <div class="mb-3">
                <label class="mb-0">Google Tag Manager</label>
                <p class="text-muted mb-2">Vul hier het GTM ID in.</p>
                <b-form-input v-model="form.google_tag_manager_id" placeholder="GTM-XXXXXXXX" />
              </div>
              <hr />
              <div class="mb-3">
                <label class="mb-0">Facebook Pixel</label>
                <p class="text-muted mb-2">Vul hier het Facebook Pixel ID in.</p>
                <b-form-input v-model="form.facebook_pixel_id" placeholder="1234567654321" />
              </div>
              <hr />
              <div class="mb-3">
                <label class="mb-0">Webhook</label>
                <p class="text-muted mb-2">Een webhook roept een url (POST) aan zodra een bestelling geplaatst en betaald is.</p>
                <b-form-input v-model="form.webhook_url" placeholder="https://mijnwebsite.nl/webhook" />
              </div>
            </b-card>

            <b-card class="mb-4">
              <h3 class="fs-5">ActiveCampaign</h3>
              <div class="mb-3">
                <label class="mb-0 required">Ingeschakkeld</label>
                <p class="text-muted mb-2">Wil je contacten met ActiveCampaign synchroniseren?</p>
                <b-form-select v-model="form.activecampaign_enabled" :options="yesNoOptions"/>
              </div>
              <template v-if="form.activecampaign_enabled">
                <hr />
                <div class="mb-3">
                  <label class="mb-0">Host</label>
                  <p class="text-muted mb-2">Vul hier de ActiveCampaign URL in.</p>
                  <b-form-input @change="getAcLists()" :class="acEmpty ? null : acValid ? 'is-valid' : 'is-invalid'" v-model="form.activecampaign_url" placeholder="https://activecampaign.api-us1.com" />
                </div>
                <hr />
                <div class="mb-3">
                  <label class="mb-0">Sleutel</label>
                  <p class="text-muted mb-2">Vul hier de ActiveCampaign sleutel in.</p>
                  <b-form-input @change="getAcLists()" :class="acEmpty ? null : acValid ? 'is-valid' : 'is-invalid'" v-model="form.activecampaign_key" placeholder="0a6465cb8d16bb90935168bddfc7d49748a011ed" />
                </div>
                <hr />
                <div class="mb-3">
                  <label class="mb-0">Lijst</label>
                  <p class="text-muted mb-2">Vul hier de standaard lijst in waarop een nieuwe gebruiker moet worden ingeschreven.</p>
                  <b-form-select :options="acListsOptions" v-model="form.activecampaign_list" :disabled="!acValid" />
                </div>
              </template>
            </b-card>
            <div class="mb-3 text-right">
                <b-button type="submit" variant="primary" :disabled="invalid || isSubmited">
                    Opslaan
                    <b-spinner small v-if="isSubmited" class="align-middle" />
                </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </b-container>
    </div>
  </template>
  
<script>
  import { mapActions } from 'pinia'
  import { useShopStore } from '@/stores/shop'
  import { ValidationObserver, ValidationProvider } from 'vee-validate';

  export default {
    metaInfo: {
        title: 'Koppelingen',
    },
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data() {
        return {
          acLists: [],
          acValid: false,
          acEmpty: true,
          isSubmited: false,
          form: {
            tickets_via_whatsapp: 0,
            google_tag_manager_id: null,
            facebook_pixel_id: null,
            webhook_url: null,
            activecampaign_enabled: null,
            activecampaign_url: null,
            activecampaign_key: null,
            activecampaign_list: null,
          },
          yesNoOptions: [
            { value: true, text: 'Ja' },
            { value: false, text: 'Nee' },
          ]
        }
    },
    computed: {
      acListsOptions() {
        let obj = [];
        obj.push({ value: null, text: 'Geen' });
        this.acLists.forEach(
          item => {
            obj.push({ value: item.id, text: item.name });
          }
        );

        return obj;
      }
    },
    methods: {
      ...mapActions(useShopStore, ['setLoading']),
      getAcLists: function() {
        this.acEmpty = true;
        this.$axios.post("https://api.tixgo.nl/activecampaign/lists", this.form)
            .then( response => {
              if (response.data.status == 'success') {
                this.acLists = response.data.data;
                this.acValid = true;
                this.acEmpty = false;
              } else {
                this.form.activecampaign_list = null;
                this.acValid = false;
                this.acEmpty = false;
              }
        });
      },
      getOrganisation: function() {
        this.setLoading(true);
        this.$axios.get("https://api.tixgo.nl/organisations/show")
            .then( response => {
              this.form = response.data;
              this.getAcLists();
              this.setLoading(false);
        });
      },
      saveForm() {
        this.isSubmited = true;
        this.$axios.put("https://api.tixgo.nl/links/update", this.form)
            .then( response => {
              response;
              
              this.$toast.open({
                  message: 'Je wijzigingen zijn succesvol opgeslagen',
                  type: 'success',
              });

              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });

              //this.$router.push('/manage/organisation');

              this.isSubmited = false;
        });
      }
    },
    created() {
      this.getOrganisation();
    }
  }
</script>